@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
*{
  margin:0;
  padding:0;
}
*,
::after,
::before {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
:root{
  --Orange-Light-5: #FFF3EB;
  --Orange-Light-10: #FFE8D7;
  --Orange-Light-BASE: #FF8B38;
  --brown-light-active-30: #A08A80;
  --Brown-Light-40: #806356;
  --Brown-BASE: #411501;
  --brown-normal-active: #341101;
  --White: #FFFBFB;
  --Gold: #DAA520;
  --Black: #19100E;
  --Grey-Mid: #C9C6C5;
  --Grey-Dark: #ACA6A6;
  --Grey-text: #666;
}
.heading-large{
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
  letter-spacing: 0.6px;
}
.Heading-mid{
font-family: Lora;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 28.8px */
letter-spacing: 0.6px;}
.heading-semibold{
  font-family: Lora;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 38.4px */
  letter-spacing: 0.6px;
  
}
.heading-small{
  font-family: Lora;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 120%;
letter-spacing: 0.6px;
}
.body-small{
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123%; 
  letter-spacing: 0.56px;
}
.body-mid{
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.64px;
}
.body-large{
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%; 
  letter-spacing: 0.108px;
}
.body-large-semibold{
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
  letter-spacing: 0.108px;
}

/* Lora/X Small */
.lore-x-small{


font-family: Lora;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 123%; /* 19.68px */
letter-spacing: 0.64px;
}

/* open sans/Title/regular */
.title-regular{
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 141%; /* 28.2px */
letter-spacing: 0.6px;
}
/* UX4G/Label/label-1 */

.label-1{
  color: var(--brown-light-active-30);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}
.primary-button{
  border: 2px solid var(--Orange-Light-BASE,#FF8B38);
  font-family: Lora;
  font-style: normal;
  line-height: normal;
  cursor: pointer;

}
.primary-button-51{
  display: inline-flex;
  padding: 10px 51px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 400;
}
.primary-button-30{
  display: inline-flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;

}
.primary-button-20{
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
}