/* .Quotation-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 79px 0px 135px 0px;
  }

  .Quotation-page-title {
    width: 100%;
    text-align: left;
    font-family: Lora;
    font-weight: bold;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding-left: 100px;
  
    color: var(--Brown-BASE);
  }
  .Quotation-page-content {
    display: flex;
    gap: 47px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .Quotation-page-component {
    flex: 1;
  }
  
  @media (max-width: 768px) {
    .Quotation-page-title {
      text-align: center;
      padding-left: 0;
      font-size: 20px;
    }
  
    .Quotation-page-content {
      flex-direction: column;
      gap: 20px;
      padding: 0 20px;
      width: 100%;
    }
  
    .Quotation-page-component {
      width: 100%;
    }
  } */

  .Quotation-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 79px 10px 135px 10px;
  }
  
  .Quotation-page-title {
    width: 100%;
    font-family: Lora;
    margin-bottom: 20px;
    padding-left: 100px;
    color: var(--Brown-BASE);
  }
  
  .Quotation-page-content {
    display: flex;
    gap: 47px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .Quotation-page-component {
    flex: 1;
  }
  
  @media (max-width: 1340px) {
    .Quotation-page {
      display: flex;
    flex-direction: column;
    align-items: center;
      padding: 50px;
    }
  }
  
  @media (max-width: 768px) {
    .Quotation-page {
      padding: 20px;
    }
  
    .Quotation-page-title {
      padding-left: 20px;
    color: var(--Brown-BASE);

    }
  
    .Quotation-page-content {
      flex-direction: column;
      gap: 20px;
      padding: 0 10px;
      width: 100%;
    }
  
    .Quotation-page-component {
      width: 100%;
    }
  }
  