.our-process {
  text-align: center;
  background: var(--Orange-Light-5, #fff3eb);
  width: 100%;
}

.ourprocess-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 38px;
  margin-top: 40px;
  margin-bottom: 26px;
}
.heading-toggle {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.heading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ourprocess-heading .heading-text {
  color: var(--Brown-BASE);
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.6px;
}

.ourprocess-heading .heading-line {
  width: 196.041px;
  height: 4px;
  background: #daa520;
  margin: 0;
}


.toggle-icon {
  cursor: pointer;
  transition: transform 0.3s ease; 
}

.toggle-icon.rotate {
  transform: rotate(180deg); 
}


.timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 45px;
  position: relative;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.step-text {
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.108px;
  margin-top: 16px;
}

.step-icon,
.step-text {
  opacity: 0.5;
  transition: opacity 0.5s ease;
  color: var(--Black, #19100e);
}

.step-icon.active,
.step-text.active {
  opacity: 1;
}

.step img {
  width: 52px;
  height: 52px;
}

.dash {
  position: relative;
  width: 45px;
  height: 1px;
  background: transparent;
  border-top: 2px dashed;
  padding-top: 18px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.dash.visible {
  opacity: 1;
}

@media (max-width: 767px) {
  .step-container {
    margin-right: 10px;
  }
  .step img {
    width: 30px;
    height: 30px;
  }
  .step-text {
    font-family: "Open Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    letter-spacing: 0.088px;
    margin-top: 16px;
  }
  .dash {
    width: 50px;
  }
  .step-container:last-child {
    margin-right: 0;
  }
}
