.contactUsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 58px;
  padding-left: 96px;
  flex-wrap: wrap;
  padding-bottom: 196px;
}
h2 {
  color: var(--Brown-BASE, #411501);
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; 
  letter-spacing: 0.6px;
}
.headingline{
  width: 332.056px;
  height: 4px;
  background: #DAA520;
  margin: 16px 0px 0px 22px;
}
.heading{
  margin-bottom: 40px;
}

.contactForm {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0 58px;
  /* margin-bottom: 196px; */
}
Form{
  width: 100%;
  max-width: 633px;
}



.formGroup {
  margin-bottom: 15px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 11px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  color: var(--Black, #19100E);
  font-family: Lora;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123%; /* 19.68px */
  letter-spacing: 0.64px;
}

.formGroup .input{
  color: var(--Grey-Dark, #ACA6A6);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.64px;
  width: 100%;
  box-sizing: border-box; 
  display: flex;
  width: 633px;
  padding: 12px 16px;
  align-items: center;
  gap: 48px;
  border-radius: 4px;
  border: 1px solid var(--Grey-Dark, #ACA6A6);
}

.submitButton {
  cursor: pointer;
  display: inline-flex;
  padding: 10px 51px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--Orange-Light-BASE, #FF8B38);
  margin: 44px auto;
  display: block;
  border: none;
  color: var(--White, #FFFBFB);
}

.contactImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 600px;
  height: 562px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Brown-BASE, #411501); 
}

.contactImageWrapper {
  position: absolute;
  width: 600px;
  height: 562px;
}

.contactImage {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 600px;
  height: 562px;
  border-radius: 8px;
}
@media (max-width: 768px) {
  .contactUsContainer {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .contactForm {
    margin: 0;
    max-width: 100%;
    padding: 0 10px;
  }
  .contactImageContainer {
    display: none;
  }
  .submitButton {
    justify-content: center;
  }
  .formGroup {
    margin-left: 0;
    width: 100%;
    padding: 0 10px;
  }
  .formGroup .input {
    width: calc(100% - 20px);
    padding: 12px 10px;
  }
}
