.project-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  margin: 16px;
}
.project-images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-right: 16px;
  gap: 24px;
}
.project-image {
  width: 456px;
  height: 446px;
  flex-shrink: 0;
  border-radius: 4px;
  background: lightgray 50% / cover no-repeat;
}
.project-info {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 456px;
  height: 446px;
}
.client-info-card {
  color: var(--Grey-text, #666);
}
.project-summary {
  margin: 16px 16px 0px 0px;
}
.info-head {
  margin-bottom: 16px;
}
.status-button {
  display: flex;
  width: 443px;
  padding: 15px 61px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--White, #fffbfb);
  border: none;
  border-radius: 8px;
  background: var(--Brown-BASE, #411501);
}
.status-button.completed {
  background-color: #4caf50;
}
.status-button.in-progress {
  background-color: #ff9800;
}
.journey-album-container {
  padding: 48px 0 48px 48px;
}
.journey-album-heading{
  margin-bottom:32px;
}
.journey-album-container h2 {
  color: var(--Brown-BASE);
}
.journey-album-scroller {
  display: flex;
  overflow-x: auto;
  gap: 24px;
}
.journey-album-scroller img {
  flex: 0 0 auto;
  width: 336px;
  height: 284px;
  object-fit: cover;
  border-radius: 4px;
}
.journey-album-scroller::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.image-count{
  margin: 16px 54px;
  text-align:right;
  color: var(--Grey-text, #666);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 141%;
  letter-spacing: 0.6px;
}

@media (max-width: 1472px) {
  .project-info {
    border-radius: 10px;
    border: 0.5px solid var(--Brown-BASE, #411501);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
      0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    padding: 20px;
    height: auto;
    width: auto;
  }
}
@media (max-width: 1030px) {
  .project-image {
    width: 48%;
    flex-shrink: 0;
    border-radius: 4px;
  }
}

@media (max-width: 768px) {
  .project-image {
    width: 456px;
    height: 446px;
  }
}
@media (max-width: 511px) {
  .project-image {
    width: 300px;
    height: 300px;
  }
  .project-info {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 300px;
    height: auto;
  }
  .status-button {
    display: flex;
    width: 255px;
  }
  .journey-album-container {
    padding: 30px 0 30px 30px;
  }
}