.contact-us-form {
  width: 911px;
  flex-shrink: 0;
  min-height: 539px;
  border-radius: 10px;
  border: 1px solid var(--Grey-Dark);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 2px 1px rgba(0, 0, 0, 0.15);
  padding: 46px 54px 70px 42px;
  box-sizing: border-box;
  overflow: hidden; 
}
.form-row {
  display: flex;
  gap: 40px;
  width:100%
}
.contact-us-form-group {
  gap:39px;
}
.contact-us-form-group label {
  display: block;
  margin-bottom: 10px;
  color: #000;
  font-family: Lora;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123%;
  letter-spacing: 0.64px;
}  
.contact-us-form-group .input {
  width: 388px;
  padding: 12px 16px;
  align-items: center;
  gap: 48px;
  border-radius: 4px;
  border: 1px solid var(--Grey-Dark, #ACA6A6);
  margin-bottom: 30px;
}
.contact-us-form-group .location-input {
  width: 820px;
  height: 73px;
  padding: 0px 16px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--Grey-Dark, #ACA6A6);
  margin-bottom: 25px;
}
.contact-us-form-group textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
} 
.contact-us-form-group input[type="checkbox"] {
  width: auto;
}
.form-row-agree {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 821px;
  flex-wrap: wrap;
}
.checkbox-group {
  display: flex;
  align-items: center;
  gap:10px;
  max-width: 100%;
}
.checkbox-group label {
  flex: 1; 
  word-break: break-word; 
}
.button{
  background: var(--Orange-Light-BASE, #FF8B38);
  color: var(--White, #FFFBFB);
}


@media (max-width: 950px) {
  .contact-us-form{
    padding: 40px;
    width: 100%  
  }
  .form-row {
    flex-direction: column;
    gap: 0px;
  }
  .contact-us-form-group{
    gap:10px;
  }
  .contact-us-form-group .input{
    width: 100%;
  }
  .contact-us-form-group .location-input{
    width: 100%
  }
  .form-row-agree {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }

  .checkbox-group {
    justify-content: flex-start;
  }
  .checkbox-group label {
    flex: 1; 
    word-break: break-word; 
  }
}
@media (max-width: 768px) {
  .checkbox-group {
    flex-direction: row;
    align-items: flex-start;
  }
  
  .checkbox-group label {
    text-align: left;
    max-width: 60%;
  }
}
