.TermsServices {
  text-align: center;
  padding: 20px;
}
.TermsServices .TermsServices-heading {
  text-align: left;
  color: var(--Brown-BASE, #411501);
  margin-left: 89px;
  margin-bottom: 32px;
}
.TermsServices .buttons {
  margin-bottom: 32px;
}
.spaced-list {
  margin-left: 25px;
}
.TermsServices .buttons button {
  max-width: 461px;
  width: 95%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: var(--Grey-Dark, #aca6a6);
  border: none;
  cursor: pointer;
  font-family: Lora;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.6px;
  background-color: white;
}
.TermsServices .buttons .active {
  background: var(--Orange-Light-5, #fff3eb);
  color: var(--Orange-Light-BASE, #ff8b38);
}
.TermsServices .show-detail {
  margin: 24px 77px 68px 77px;
}

.TermsServices .detail p {
    color: var(--Grey-text);
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .TermsServices .TermsServices-heading {
      text-align: left;
      color: var(--Brown-BASE, #411501);
      margin-left: 10px;
    }
    .TermsServices .show-detail {
        margin: 24px 24px;
    }
}