.portfolio {
  text-align: center;
  padding: 20px;
}
.portfolio .portfolio-heading {
  text-align: left;
  color: var(--Brown-BASE, #411501);
  margin-left: 89px;
}
.portfolio .portfolio-text {
    color: var(--Grey-Dark, #ACA6A6);
    text-align: justify;
    margin-left: 89px;
    margin-bottom:61px;
}
.portfolio .buttons {
  margin-bottom: 32px;
  display: none; /*remove this for getting buttons*/
}
.portfolio .buttons button {
  max-width: 708px;
  width: 95%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: var(--Grey-Dark, #ACA6A6);
  border: none;
  cursor: pointer;
  font-family: Lora;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; 
  letter-spacing: 0.6px;
  background-color: white;
}
.portfolio .buttons .active {
  background: var(--Orange-Light-5, #FFF3EB);
  color: var(--Orange-Light-BASE, #FF8B38);
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
}
.view-more {
  margin-top: 20px;
  cursor: pointer;
  font-size: 1em;
  color: var(--White);
  background: var(--Orange-Light-BASE);
}

@media (max-width: 768px) {
  .portfolio .portfolio-heading {
    text-align: left;
    color: var(--Brown-BASE, #411501);
    margin-left: 10px;
  }
  .portfolio .portfolio-text {
    color: var(--Grey-Dark, #ACA6A6);
    text-align: justify;
    margin-left: 10px;
    margin-bottom:25px;
}
}