
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.modal-content {
  width: 1265px;
  height: 653px;
  flex-shrink: 0;
  background: white;
  padding: 28px 68px 69px 58px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.modal-header {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}
.modal-header h2 {
  margin: 0;
}
.modal-header .line {
  width: 228px;
  height: 2px;
  margin: 10px auto;
  background: #DAA520;
}
.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}
.modal-body {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Service-Image {
  width: 424px;
  height: 469px;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  border-radius: 8px;
}
.service-form {
  display: flex;
  flex-direction: column;
  margin-left: 82px;
  margin-top: 17px;
  flex-grow: 1;
}
.service-form  label {
  margin-bottom: 10px;
  color: var(--Black, #19100E);
  font-family: Lora;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123%; 
  letter-spacing: 0.64px;
}
.service-form input,
.service-formtextarea {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 48px;
  max-width: 633px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--Grey-Dark);
}
.service-form input{
  margin-bottom: 16px;
  max-width: 633px;
  width: 100%;
}
.modal-submit {
  align-self: center;
  background: var(--Orange-Light-BASE);
  color: var(--White);
  margin-top:44px;
}
@media (max-width: 768px) {
  
  .modal-content {
    width: 90%;
    height: auto;
    padding: 28px 68px 69px 0px;
  }
  .modal-header {
    flex-direction: column;
  }
  .modal-body {
    flex-direction: column;
  }
  .modal-content img {
    display: none;
  }
  .service-form  form {
    width: 100%;
    margin-left: 0;
  }
}