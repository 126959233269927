.QTcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:60px;
  margin-bottom: 40px;
}
.QTSection {
  width: 100%;
  max-width: 1416px;
}
.QTSection h2 {
  color: var(--Brown-BASE, #411501);
  font-family: Lora;
  font-size: 40px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.6px;
}
.QTSection p {
  color: var(--Grey-text, #666);
  text-align: justify;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.8px;
  margin-bottom: 20px;
}
.Partnership {
  display: flex;
  flex-wrap: wrap; 
  gap: 24px;
  justify-content: center;
  margin-top:44px;
  margin-bottom:40px;
}
.Partnership-Card {
  width: 336px;
  height: 156px;
  flex-shrink: 0;
  padding: 16px;
  background-color: yellow;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
  