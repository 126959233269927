.custom-toast {
    position: fixed;
    top: 30px;
    right: 20px;
    background: White;
    color: #000;
    padding: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
    max-width: 596px;
    width:80%;
    height:auto;
}
.toast-message{
    color: #000;
    margin:16px 40px;
}
.custom-toast-progress {
    position: absolute;
  bottom: 0;
  left: 0;
    width: 100%;
    height: 2.5px;
    background: #FF8B38;
    animation: progressBar 3s linear forwards;

}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes progressBar {
    from { width: 100%; }
    to { width: 0%; }
}
