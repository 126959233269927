.contact-us-info {
  display: flex;
  flex-direction: column;
  height: 539px;
  gap: 45px;
}
.contact-us-info-item {
  width: 360px;
  height: 143px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0.5px solid #aca6a6;
  background: var(--White, #fffbfb);
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 32px 0px 0px 33px;
  display: flex;
  gap: 29px;
}
.contact-us-info .contact-logo {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}
.conatct-info-detail {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.conatct-info-detail-address {
  width: 204px;
  color: var(--Black, #19100e);
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 138%;
  letter-spacing: 0.88px;
}
.contact-us-info a {
  color: var(--brown-normal-active);
}
@media (max-width: 1340px) {
  .contact-us-info {
    display: flex;
    flex-direction: row;
    height: auto;
    gap: 20px;
    flex-wrap: wrap;
  }
  .contact-us-info-item {
    width: 360px;
    height: 143px;
    display: flex;
  }
}
@media (max-width: 767px) {
  .contact-us-info-item {
    flex-shrink: 0;
    border-radius: 10px;
    border: 0.5px solid #aca6a6;
    background: var(--White, #fffbfb);
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    padding: 32px 0px 0px 20px;
    display: flex;
    gap: 18px;
  }
}

@media (max-width: 415px) {
  .contact-us-info-item {
    width: 330px;
    height: 143px;
    display: flex;
  }
}
