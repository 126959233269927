.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding-bottom:38px ;
}
.row1 {
  width: 100%;
  display: flex;
  padding-left: 104px;
  padding-top: 84px;
  box-sizing: border-box;
}
.row1 .column1 .ourValues {
  color: var(--Brown-BASE, #411501);
  font-family: Lora;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38.4px;
  letter-spacing: 0.6px;
}
.row1 .column1 .oneStopSolution {
  color: var(--Brown-BASE, #411501);
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.6px;
}
.row1 .column1 .yourSolution {
  color: var(--Brown-BASE, #411501);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.144px;
}
.column1 .yourSolution span {
  color: var(--Gold, #DAA520);
  font-weight: 600;
}
.row1 .column2 {
  flex: 1;
  padding-left: 88px;
  padding-right: 160px;
  display: flex;
  align-items: flex-end;
  color: var(--Black, #19100E);
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.94px;
  letter-spacing: 0.108px;
}
.row2 {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 32px;
}
.line {
  width: 520px;
  height: 4px;
  background: #411501;
}
.row3 {
  display: flex;
  justify-content: center;
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;
}
.image-container {
  width: 456px;
  height: 388px;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
  overflow: hidden; /* Ensures the ::after pseudo-element stays within bounds */
}
.image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(122, 103, 103, 0.03) -32.4%, rgba(0, 0, 0, 0.85) 56.16%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  border-radius: 8px;
  pointer-events: none; /* Ensures the pseudo-element doesn't interfere with text hover */
}
.image-container:hover::after {
  opacity: 1;
}
.image-container .image-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 26px;
  color: var(--White, #FFFBFB);
  font-family: "Open Sans";
  font-style: normal;
  z-index: 1; /* Ensures text stays above the pseudo-element */
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.image-container:hover .image-text {
  opacity: 1;
}
.image-container .image-text h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.12px;
}
.image-container .image-text p {
  color: var(--White);
  text-align: justify;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  letter-spacing: 0.8px;
}
@media (max-width: 576px) {
  .row1 {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  .row1 .column1,
  .row1 .column2 {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .row2 {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .line {
    width: 100%;
    max-width: 100%;
    margin-bottom: 16px;
  }
  .row3 {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .image-container {
    height: 300px;
    max-width: 300px;
    padding: 0;
  }
  .image-container .image-text {
    padding: 20px;
  }
}