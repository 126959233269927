.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 79px 10px 135px 10px;
}
.contact-page-title {
  width: 100%;
  font-family: Lora;
  margin-bottom: 20px;
  padding-left: 100px;
  color: var(--Brown-BASE);
}
.contact-page-content {
  display: flex;
  gap: 47px;
  justify-content: center;
  flex-wrap: wrap;

}
.contact-page-component {
  flex: 1;
}
@media (max-width: 1340px) {
  .contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
  }
}
@media (max-width: 768px) {
  .contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  .contact-page-title {
    padding-left: 20px;
    color: var(--Brown-BASE);
  }
  .contact-page-content {
    flex-direction: column;
    gap: 20px;
    padding: 0 10px;
    width: 100%;
  }

  .contact-page-component {
    width: 100%;
  }
}