.faq {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.faq-container {
  border-radius: 4px;
  border: 2px solid var(--Brown-Light, #ECE8E6);
  padding: 18px 162px 79px 162px;
  text-align: center;
}

.faq-container h1 {
  text-align: center;
  margin-bottom: 61px;

}

.faq-grid {
  display: flex;
  flex-wrap: wrap;
  column-gap: 64px;
  row-gap: 23px;
  justify-content: center;
}

.faq-item {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.faq-question {
  display: flex;
  padding: 12px;

  justify-content: space-between;

  cursor: pointer;
  font-weight: bold;
  border: 1px solid var(--Grey-text, #666);
}

.faq-answer {
  margin-top: 5px;
  display: none;
  padding-left: 10px;
  text-align: start;
}

.faq-item.active .faq-answer {
  display: block;
}

@media (max-width: 768px) {
  .faq-grid {
    flex-direction: column;
  }
  .faq-container {
    padding: 18px 20px 79px 20px;
    text-align: center;
  }
  .faq-item {
    flex: 1 1 100%;
  }
}
