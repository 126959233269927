/* FloatingWhatsAppButton.css */
.floating-whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000; /* Ensure it is above other elements */
}

.floating-whatsapp-button:hover {
  background-color: #1ebc57;
}
