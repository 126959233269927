.Privacy-Policy {
  text-align: center;
}
.Privacy-Policy-heading {
  color: var(--Brown-BASE, #411501);
  /* Lora/Heading large */
  text-align: left;
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 48px */
  letter-spacing: 0.6px;
  margin-top: 50px;
  margin-left: 87px;
  margin-bottom: 32px;
}
.Privacy-Policy .show-detail {
  margin: 24px 77px 68px 77px;
}

.Privacy-Policy .detail p {
  color: var(--Grey-text);
  margin-bottom: 10px;
}

@media (max-width: 768px) {
    .Privacy-Policy .Privacy-Policy-heading {
      text-align: left;
      color: var(--Brown-BASE, #411501);
      margin-left: 24px;
    }
    .Privacy-Policy .show-detail {
        margin: 24px 24px;
    }
}