.clientfeedbackContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-content: center; 
    gap: 16px;
    margin-bottom: 130px;
}
.clientfeedbackContainer .textBoxContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 360px;
    margin-top: 7px;
    padding-right: 12px;
}
.clientfeedbackContainer .heading {
    color: var(--Brown-BASE, #411501);
    font-family: Lora;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.6px;
}
.clientfeedbackContainer .headingline{
    width: 294.462px;
    height: 4px;
    background: #DAA520;
    margin: 19px 0px 26px 12px;
}
.clientfeedbackContainer .subheading {
    color: #000;
    width: 293.538px;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%; 
    letter-spacing: 0.108px;
}
.clientfeedbackContainer .feedbackContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    margin-top: 20px;
}
  
  