.project-card {
  display: flex;
  max-width: 456px;
  width: 100%;
  height: 379px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1),
    0px 2px 1px 0px rgba(0, 0, 0, 0.15);
}
.card-image {
  width: 456px;
  height: 298px;
  flex-shrink: 0;
  border-radius: 4px 4px 0px 0px;
  background: lightgray 50% / cover no-repeat;
}

.card-image img {
  max-width: 456px;
  width: 100%;
  height: 298px;
}

.card-details {
  max-width: 456px;
  width: 100%;
  flex-shrink: 0;
  background: var(--White);
  padding: 16px 25px 11px 16px;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.client-name,
.location {
  font-size: 20px;
  color: var(--Black);
}

.floor-area {
  color: var(--Grey-Dark);
}
.location {
  color: var(--Grey-Dark, #aca6a6);
  margin-top: 8px;
}

@media (max-width: 456px) {
  .project-card {
    width: 300px;
  }
  .card-image {
    width: 300px;
  }
  .card-image img {
    width: 300px;
  }
}
