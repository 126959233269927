.Explore-services-container {
    display: flex;
    flex-direction: column;
    max-width: 837px;
    min-height: 322px;
    justify-content: center;
    
} 
.Explore-services-container h2 {
  color: var(--Brown-BASE);
  margin-left: 34px;
}
.Explore-services-container p {
  margin-left: 34px;
  margin-right: 93px;
  color: var(--Grey-Dark);
}
@media (max-width: 768px) {
  .Explore-services-container p {
    margin-left: 34px;
    margin-right: 34px;
    color: var(--Grey-Dark);
  }
}