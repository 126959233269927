.clientFeedbackContainer {
    box-sizing: border-box;
    padding: 30px 20px 28px 20px ;
    position: relative;
    font-family: Arial, sans-serif;
    width: 316px;
    height: 271px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 0.5px solid var(--Brown-BASE, #411501);
}
.clientFeedbackContainer .rating{
    width: 116px;
    height: 20px;
    flex-shrink: 0;
    margin-top: 8px;
    margin-bottom: 4px;
}
.clientFeedbackContainer .clientName {
    width: 200px;
    height: 22px;
    flex-shrink: 0;
    color: #000;
    font-family: Lora;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 123%;
    letter-spacing: 0.64px;
}
  
.clientFeedbackContainer .clientMessage {
    width: 276px;
    height: 124px;
    flex-shrink: 0;
    color: #000;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 123%;
    letter-spacing: 0.56px;
    margin-bottom: 13px;
}
  
.clientFeedbackContainer .clientService {
    width: 200px;
    height: 22px;
    flex-shrink: 0;
    color: var(--Grey-Dark, #ACA6A6);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 123%; 
    letter-spacing: 0.56px;
}
.clientFeedbackContainer .iconContainer{
    position: absolute;
    bottom: -12px;
    right: 12.42px;
    display: flex;
    gap: 10px;
    height: 25.415px;
    flex-shrink: 0;
  }
  