.Partnership-Card{
    width: 336px;
    height: 156px;
    flex-shrink: 0;
    padding: 16px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
