.portfolio-card {
    max-width: 456px;
    width: 100%;
} 
.image-container-protfolio {
    display: flex;
    position: relative;
}
.left-image, .right-image {
    width: 50%;
    height: 268px;
    object-fit: cover;
}
.left-image{
  border-radius: 10px 0px 0px 0px;
background: lightgray 50% / cover no-repeat;
}
.right-image{
  border-radius: 0px 10px 0px 0px;
background: lightgray 50% / cover no-repeat;
}
  .icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 78px;
    height: 78px;
    flex-shrink: 0;
    background-color: var(--Orange-Light-BASE, #FF8B38);
    display: flex; 
    align-items: center;
    justify-content: center;
    
  }
  
  .center-icon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }
  .address-container {
    border-radius: 0px 0px 10px 10px;
    border-top: 4px solid var(--Orange-Light-BASE, #FF8B38);
    opacity: 0.85;
    background: var(--White, #FFFBFB);
    width: 100%;
    height: 76px;
  }
  .address-container p {
    color: var(--Brown-Light-50, #613C2B);
    font-family: Lora;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.6px;
    padding: 27px 0px 25px 24px;
  }