.work-album-container {
    padding: 48px 0 48px 48px;
}
.work-album-container h2{
  color: var(--Brown-BASE);
}
.work-album-scroller {
    display: flex;
    overflow-x: auto;
    gap: 24px;
}
.work-album-scroller img {
    flex: 0 0 auto;
    width: 456px;
    height: 311px;
    object-fit: cover;
    border-radius: 4px;
}
.work-album-scroller::-webkit-scrollbar {
    width: 0;
    height: 0;
}