.ServicePageQuotation-container {
    background: var(--White, #FFFBFB);
    box-shadow: 1px 0px 1px 0px rgba(0, 0, 0, 0.35), 0px 2px 2px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 421px;
    flex-shrink: 0;
    gap:240px;
    margin-top: 32px;
    margin-bottom: 44px; 
}
.ServicePageQuotation-text-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; 
}
.ServicePageQuotation-image-column img {
    width: 100%;
    height: 100%;
    max-width: 545px;
    max-height: 341px;
}
.ServicePageQuotation-container .Quotation-button{
    background: var(--Orange-Light-BASE);
    color: var(--White);
    
}
.itallic{
    font-style: italic;
    margin-bottom: 27px;
}

@media (max-width: 768px) {
    .ServicePageQuotation-container {
        flex-direction: column; 
        min-height: auto; 
        gap: 40px;
    }

    .ServicePageQuotation-text-column {
        padding: 20px;
    }
}