.container_portfolio {
  padding: 45px;
  text-align: center;
  max-width: 100%;
}
  
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-left: 51px;
}
.heading {
  margin: 0;
  flex-grow: 1;
  text-align: left;
  color: var(--Brown-BASE, #411501);
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; 
  letter-spacing: 0.6px;
}
.headingline{
  max-width: 332.056px;
  width: 80%;
  height: 4px;
  background: #DAA520;
  margin: 16px 0px 0px 22px;
}
.heading_text{
  width: 450px;
  color: #000;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%; /* 23.94px */
  letter-spacing: 0.108px;
  margin-top: 17px;
}
.navButtons {
  display: flex;
}

.navButton {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  background: none;
  border: none;
  cursor: pointer; 
}
  
  .cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center; 
    gap: 24px;
  }
  
  

  @media (max-width: 768px) {
    .container_portfolio {
      padding: 20px;

      }
    .header {
      margin-left: 0;
      flex-direction: column;
      align-items: flex-start;
    }

    .headingline {
      max-width: 70%;
      height: 4px;
      background: #DAA520;
      margin-top: 10px;
    }
  
    .heading_text {
      width: 90%;
      font-size: 16px;
      margin: 0px 0px;
      padding: 0px;
      
    }
  
    .navButtons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-bottom: 30px;
    }
  }