
.slider_container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 533px;
    flex-shrink: 0;
  }
  
  .slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
    height: 100%;
    filter: brightness(0.6);
  }
  
  .slide {
    min-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .controls {
    position: absolute;
    bottom: 24px;
    right: 24px;
    display: flex;
    gap: 10px;
    margin-right: 24px;
  }
  
  .button-icon {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  
  .slider-content {
    position: absolute;
    bottom: 84px;
    left: 78px;
  }
  
  .slider-content h2 {
    color: var(--White, #FFFBFB);
    font-family: Lora;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; 
    letter-spacing: 0.6px;
    margin-bottom:16px;
    
  }
  
  .slider-content p {
    color: var(--White);
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 28.8px */
letter-spacing: 0.144px;
margin-bottom:16px;
  }
  
  .slider-content p span {
    color: var(--Gold);
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 120%;
letter-spacing: 0.144px;

  }
  
  .quote-button {
    display: flex;
    width: 238px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--Orange-Light-BASE);
    color: var(--White);
    font-family: Lora;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    border:none;

  }

  .button-link{
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .slider-content {
      position: absolute;
      bottom: 84px;
      left: 15px;
      width: 80%;
    }
  }