.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--Orange-Light-10);
  padding: 20px;
}

.footer .company-logo {
  width: 170px;
  height: 73px;
  margin: 31px 0px 16px 0px;
  flex-shrink: 0;
  background: -31.324px -4.088px / 138.772% 110% no-repeat;
}

.footer .slogan-section {
  color: var(--brown-light-active-30);
  text-align: center;
}

.footer .email-section {
  margin: 16px 0px;
  color: var(--brown-light-active-30);
}

.footer .email-section .email {
  text-decoration: none;
  color: var(--Orange-Light-BASE);
}

.footer .address-contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.address-contact-logo {
  width: 24px;
  height: 24px;
}

.footer .address-contact-section .footer-address,
.footer .address-contact-section .footer-contact {
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 0.5px solid var(--Orange-Light-BASE);
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  color: var(--Grey-text, #666);
}
.footer .address-contact-section .footer-contact{
  justify-content: center;
}

.footer .address-contact-section .footer-address p,
.footer .address-contact-section .footer-contact p {
  margin: 0;
}

.footer .links-section {
  display:flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 32px 12px;
  justify-content: center;
  gap: 16px;
  border-top: 1px solid var(--Grey-Mid);
  border-bottom: 1px solid var(--Grey-Mid);
  margin: 16px 0; 
}

.footer .links-section .links {
  
  text-decoration: none;
  color: var(--Brown-Light-40);
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 14px 20px 21px 20px;
  width: 100%;
  box-sizing: border-box;
}

.footer-bottom .footer-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footer-bottom .footer-logo {
  width: 76px;
  height: 90px;
  flex-shrink: 0;
}

.footer-button {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer-button .contact-us {
  background: var(--Orange-Light-BASE);
  color: var(--White);
}

.footer-button .get-quotation {
  color: var(--Black);
}

@media (min-width: 950px) {
  .footer .address-contact-section {
    flex-direction: row;
    gap: 46px;
  }
  
  .footer .address-contact-section .footer-address,
  .footer .address-contact-section .footer-contact {
    height: 60px;
  }

  .footer .links-section {
    flex-direction: row;
    gap: 42px;
  }
  
  .footer-bottom {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .footer-bottom .footer-left {
    flex-direction: row;
    align-items: center;
    gap: 36px;
  }
  
  .footer-bottom .footer-right {
    flex-direction: row;
    align-items: flex-end;
    gap: 34px;
  }
  
  .footer-button {
    flex-direction: row;
    gap: 26px;
    margin-bottom: 36px;
  }
}
