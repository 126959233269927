.navbar {
  width: 100%;
  flex-shrink: 0;
  background: var(--Orange-Light-10, #ffe8d7);
  position: relative;
  flex-direction: column;
  color: var(--brown-normal-active, #341101);
}

.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 48px;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.logo img {
  width: 186px;
  height: 88px;
}

.contact-container {
  display: flex;
  justify-content: space-around;
}

.contact-item {
  gap: 16px;
  display: flex;
  align-items: center;
  padding-right: 37px;
}

.contact-item-img {
  width: 36px;
  height: 36px;
  /* margin-right: 10px; */
}

.navbar-bottom {
  display: flex;
  /* flex-wrap: wrap; */
  width: auto;
  min-height: 52px;
  flex-shrink: 0;
  border-radius: 4px;
  margin-left: 48px;
  margin-right: 48px;
  background: var(--Orange-Light-BASE, #ff8b38);
  justify-content: center;
}

.navbar-bottom a {
  color: #fffbfb;
  display: flex;
  width: 121px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}
.clickable {
  cursor: pointer;
}
@media (max-width: 767px) {
  .navbar {
    flex-direction: column;
  }
  .logo img {
    width: 150px;
    height: 80px;
  }

  .navbar-top {
    width: 100%;
    justify-content: space-between;
    padding: 0 16px;
  }

  .logo {
    margin: 0;
  }

  .contact-container {
    display: none;
  }
  
  .hamburger-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .hamburger-menu img {
    width: 36px;
    height: 36px;
  }

  .navbar-bottom {
    display: none;
    flex-direction: column;
    width: 100%;
    margin: 0;
  }

  .navbar-bottom.show {
    display: flex;
  }

  .navbar-bottom a {
    width: 100%;
    justify-content: flex-start;
    padding: 12px 16px;
  }
}

@media (min-width: 768px) {
  .hamburger-menu {
    display: none;
  }
}

.navbar .contact-item a {
  color: var(--brown-normal-active, #341101);
}
