.containerOurservice {
  padding: 45px;
}
.Ourservice-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 51px;
}
.heading {
  margin: 0;
  flex-grow: 1;
  text-align: left;
  color: var(--Brown-BASE, #411501);
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.6px;
}
.headingline{
  width: 332.056px;
  height: 4px;
  background: #DAA520;
}
.navButtons {
  display: flex;
}
.navButton {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  background: none;
  border: none;
  cursor: pointer;
}
.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center; 
  gap: 25.5px;
}
.viewAllButtonContainer {
  margin-top: 50px;
  text-align: center;
}
.viewAllButton {
  width: 218px;
  padding: 10px 51px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--Orange-Light-BASE, #FF8B38);
  color: var(--White, #FFFBFB);
  font-family: Lora;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}
@media (max-width: 768px) {
  .containerOurservice {
    padding: 20px;
  }

  .Ourservice-header {
    margin-left: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .headingline {
    max-width: 70%;
    height: 2px;
    background: #DAA520;
    margin-top: 10px;
  }

  .navButtons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 30px;

  }

  .navButton {
    width: 36px;
    height: 36px;
  }

  .cardsContainer {
    gap: 15px;
  }

  .viewAllButtonContainer {
    margin-top: 30px;
  }

  .viewAllButton {
    width: 180px;
    padding: 8px 30px;
    font-size: 18px;
  }
}