.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
  }
  
  .imagecontainer {
    position: relative;
    width: 552px;
height: 278px;
flex-shrink: 0;
  }
  
  .imagecontainer img {
    width: 100%;
    height: 100%;
  }
  
  .step-number {
    position: absolute;
    top: -22.5px;
    left: -22.5px;
    background: var(--Orange-Light-BASE, #FF8B38);
    color: white;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
  }
  
  .text-box {
    padding: 31px 108px 23px 108px;
    margin: 22px 116px 36px 116px;
    border: 2px solid var(--Gold, #DAA520);
  }
  
  .text-box p {
    flex-shrink: 0;
    color: var(--brown-normal-active, #341101);
    text-align: center;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.26px;
    letter-spacing: 0.108px;
  }
  
  .text-box a {
    color: var(--Gold, #DAA520);
    font-family: Lora;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media (max-width: 768px) {
    .imagecontainer{
      width:335px;
      height: auto;
    }
    .imagecontainer img {
      width: 335px;
      height: auto;
    }
    
    .text-box {
      padding: 20px 30px;
      margin: 20px;
    }
  }