.why-choose-us {
    background-color: #fff;
} 
.why-choose-us h2 {
    color: var(--Brown-BASE, #411501);
    font-family: Lora;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 48px */
    letter-spacing: 0.6px;

    margin-bottom: 10px;
    margin-left: 96px;
}
.why-choose-us p {
    color: #000;
    /* open sans/Body/large */
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%; /* 23.94px */
    letter-spacing: 0.108px;
    margin-left: 96px;
    margin-bottom: 20px;
}
.reasons {

    display: flex;
    justify-content: center;
    gap: 24px;
    width: 100%;
    flex-wrap: wrap;
}
.reason {
    width: 337px;
    height: 417px;
    flex-shrink: 0;
    background: var(--Orange-Light-5, #FFF3EB);
}
.reason img {
    width: 238.92px;
    height: 150px;
    margin: 50px;
    flex-shrink: 0;
    background: cover no-repeat;
}
.reason h3 {
    color: #000;
    font-family: Lora;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
    letter-spacing: 0.6px;
    margin-left: 25px;
    margin-right: 25px;
}
.reason p {
    color: #000;
    text-align: justify;

    /* open sans/Body/mid */
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 21.6px */
    letter-spacing: 0.8px;
    margin-top: 12px;
    margin-left: 25px;
    margin-right: 25px;
}
  
.one-stop-solution {
    margin-left: 115px;
    margin-right: 115px;
    margin-bottom: 30px;
    margin-top: 43px;
    border: 2px solid var(--Gold, #DAA520);
} 
.one-stop-solution h3 {
    color: var(--Gold, #DAA520);
    font-family: Lora;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    letter-spacing: 0.6px;
    margin-top: 30px;
    margin-bottom: 16px;
    text-align: center;
}
.one-stop-solution p {
    color: var(--brown-normal-active, #341101);
    text-align: center;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%; 
    letter-spacing: 0.108px;
    margin-bottom: 30px;
    margin-left: 102px;
    margin-right: 102px;
}
.stats-container {
  display: flex;
}
.column {
  width: 50%;
  justify-content: space-around;
}
.dark-background {
  display: flex;
  background: var(--Brown-BASE, #411501);
  color: var(--Orange-Light-BASE, #FF8B38);
  font-family: Lora;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; 
  letter-spacing: 0.6px; 
}
.light-background {
  display: flex;
  background: var(--Orange-Light-BASE, #FF8B38);
  color: var(--Brown-BASE, #411501);
  font-family: Lora;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.6px;
}
.stat-item {
  display: flex;
  align-items: center;
  margin-bottom: 61px;
  margin-top: 68px;
  
}
.icon {
  width: 64px;
  height: 69px;
  flex-shrink: 0;
  margin-right: 10px;
}
.text {
  display: flex;
  flex-direction: column;
  
}
.dark-background .text p{
  color: var(--Orange-Light-BASE);
}
.light-background .text p{
  color: var(--Brown-BASE);
}
.number {
  font-size: 32px;
  margin-bottom: 8px;
}
.description {
  font-size: 20px;
}
@media (max-width: 768px) {
  .why-choose-us h2,
  .why-choose-us p {
    margin: 10px;
  }

  .one-stop-solution h3,
  .one-stop-solution p {
    margin-left: 20px;
    margin-right:10px;
  }

  .one-stop-solution {
    margin: 10px;
  }
  .stats-container {
    display: flex;
    flex-direction: column;
  }
  
  .column {
    width: 100%;
    justify-content: space-around;
  }
}