.Foundercontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 35px;
    max-width: 100%;
  }
  .aboutSection {
    width: 100%;
    max-width: 1416px;
  }
  .foundersSection {
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 42px;
    gap: 36px;

  }
  .founder {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .founderImageContainer {
    position: relative;
    width: 336px;
    height: 362px;
    flex-shrink: 0;
    border-radius: 4px;
    background: lightgray 50% / cover no-repeat, #D9D9D9;
  }
  .founderImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .founderText {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 66px;
    text-align: center;
    border-radius: 0px 0px 4px 4px;
    background: var(--Black, #19100E);
    box-shadow: 0px -2px 7.1px 0px rgba(0, 0, 0, 0.50);
    opacity: 1;
    padding-top: 17px;
    box-sizing: border-box;
    color: #FFF;
    font-family: Lora;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.6px;
  }
  .founderDetails {
    text-align: center;
    max-width: 50%;
  }
  .founderDetails .founderDetails-text {
    max-width: 672px;
    min-height: 298px;
    flex-shrink: 0;
    background: var(--Grey-Light, #EDEAEA);
    padding: 89px 29px 89px 56px;
  }
  h2 {
    color: var(--Brown-BASE, #411501);
    font-family: Lora;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.6px;
  }
  h3 {
    color: var(--Gold, #DAA520);
    text-align: justify;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.64px;
    margin-bottom: 7px;
  }
  p {
    color: var(--Grey-text, #666);
    text-align: justify;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: 0.8px;
  }
  

  


  @media (max-width: 1200px) {
    .foundersSection {
      flex-direction: column;
      gap: 20px;
    }
  
    .founderImageContainer {
      width: 300px;
      height: 325px;
    }
  
    .founderDetails {
      max-width: 100%;
    }
  
    .founderDetails .founderDetails-text {
      width: 100%;
      height: auto;
      padding: 100px 50px;
    }
  
    h2 {
      font-size: 32px;
    }
  }
  
  @media (max-width: 768px) {
    .foundersSection {
      flex-direction: column;
      gap: 20px;
    }
  
    .founderImageContainer {
      width: 250px;
      height: 275px;
    }
  
    .founderDetails {
      max-width: 100%;
    }
  
    .founderDetails .founderDetails-text {
      width: 100%;
      height: auto;
      padding: 60px 20px;
      box-sizing: border-box;
    }
  
    h2 {
      font-size: 28px;
    }
  }
  
  @media (max-width: 480px) {
    .foundersSection {
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
  
    .founderImageContainer {
      width: 100%;
      height: auto;
    }
  
    .founderDetails {
      max-width: 100%;
      width: 100%;
    }
  
    .founderDetails .founderDetails-text {
      width: 90%;
      padding: 20px;
      box-sizing: border-box;
      margin: 0 auto;
    }
  
    h2 {
      font-size: 24px;
    }
  }