.card {
    overflow: hidden;
    text-align: center;
    width: 263px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
  }
  
.card-image {
    width: 100%;
    height: 274px;
    object-fit: cover;
} 
.card-text {
    flex:1;
    justify-content: space-around;
    width: 100%;
    /* flex-shrink: 0; */
    color: #FFF;
    margin-top: 0px;
    padding-top: 13px;
    padding-bottom: 13px;
    font-family: Lora;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;/* 21.6px */
    letter-spacing: 0.6px;
    background: var(--Brown-BASE, #411501);
}
.card:hover {
    transform: scale(0.9);
}